import React from "react"
import { PrismicDocument } from "@prismicio/types"
import { GroupedCoursesByTechnology } from "./GroupedCoursesByTechnology"
import { Radio, Space, Typography } from "antd"
import { useRouter } from "next/router"
import { Section } from "../Section"
import { CoursesList } from "../CoursesList/CoursesList"
import { css } from "@emotion/react"
import { SerializedStyles } from "@emotion/utils"

const GroupBy = {
  zakladni: {
    value: "zakladni",
    label: "základní"
  },
  predmetu: {
    value: "predmetu",
    label: "předmětu"
  } /*,
  kalendar: {
    value: "kalendar",
    label: "kalendář"
  }*/
}

const useUrlGroupBy = () => {
  const { query } = useRouter()
  return (query?.["radit-kurzy-podle"] || GroupBy.predmetu.value) as keyof typeof GroupBy
}

const RenderGroupedCourses = ({
  title,
  groupBy,
  courses,
  children
}: {
  title?: React.ReactNode
  groupBy: keyof typeof GroupBy
  courses: PrismicDocument[]
  children?: React.ReactNode
}) => {
  switch (groupBy) {
    case "predmetu":
      return (
        <GroupedCoursesByTechnology title={title} courses={courses}>
          {children}
        </GroupedCoursesByTechnology>
      )
    /*case "kalendar":
      return <GroupedCoursesByDateIntoCalendar courses={courses}>{children}</GroupedCoursesByDateIntoCalendar>*/
    default:
      return (
        <>
          <Typography.Title level={2}>{title || "Otevřené registrace"}</Typography.Title>
          {children || null}

          <CoursesList
            courses={courses}
            css={css`
              margin: 20px 0 0 0;
            `}
            informationOnly={true}
            withSubCourse={true}
          />
        </>
      )
  }
}

export const GroupedCourses = ({ title, courses, cssProp }: Props) => {
  const { push, pathname, query } = useRouter()
  const groupBy = useUrlGroupBy()

  const handleOnChangeGroupBy = (value: string) => {
    push({ pathname, query: { ...query, "radit-kurzy-podle": value } }, undefined, { scroll: false })
  }

  return (
    <Section css={cssProp}>
      <RenderGroupedCourses title={title} groupBy={groupBy} courses={courses}>
        <Space style={{ marginBottom: 20 }} wrap={true}>
          <Typography.Text>Řadit kurzy podle:</Typography.Text>

          <Radio.Group value={groupBy} onChange={(e) => handleOnChangeGroupBy(e.target.value)}>
            {Object.entries(GroupBy).map(([, { value, label }]) => (
              <Radio.Button key={value} value={value}>
                {label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Space>
      </RenderGroupedCourses>
    </Section>
  )
}

interface Props {
  title?: React.ReactNode
  courses: PrismicDocument[]
  cssProp?: SerializedStyles
}
