import React from "react"
import { ContentAboveContent, ContentAboveWrapper, whiteText } from "../Layout/common"
import { H1, Paragraph } from "../blog/common"
import { css } from "@emotion/react"
import { md, sm } from "../../styles/mediaQuery"
import Link from "next/link"
import { Button } from "antd"
import FullWidthImage from "../Layout/FullWidthImage"
import styled from "@emotion/styled"
import { theme } from "../../styles/styles"

export const IndexHero = ({ children }: Props) => {
  return (
    <>
      <ContentAboveWrapper>
        <ContentAboveContentHero>
          <Half>
            <H1 className="ml11" style={whiteText}>
              Kreativní počítačové kurzy pro mladé
            </H1>
            <Paragraph
              style={whiteText}
              css={css`
                ${sm} {
                  font-size: 16px;
                }
              `}>
              Připojte se k nám online a objevte zábavu v programování, modelování a 3D tisku z pohodlí domova! Ideální pro děti přibližně od 9 let, naše kurzy
              nabízejí flexibilní a pohodlný způsob, jak se naučit nové dovednosti bez nutnosti cestování.
            </Paragraph>
            <Link href="/krouzky-pro-deti" title="Přejít na nabídku kroužků">
              <Button
                type="primary"
                size="large"
                css={css`
                  margin-top: 20px;
                `}>
                Přejít na nabídku kroužků
              </Button>
            </Link>
          </Half>

          {children ? children : null}
        </ContentAboveContentHero>
      </ContentAboveWrapper>

      <FullWidthImage imageBgUrl="/images/01_2022_vzdelani_budoucnosti_web_header_1920_1.jpg" height="55vh" />
    </>
  )
}

interface Props {
  children?: React.ReactNode
}

const ContentAboveContentHero = styled(ContentAboveContent)`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  align-items: center;
  margin-top: -40px;

  ${md} {
    flex-direction: column;
    gap: 20px;
    margin-top: -20px;
  }
`

const Half = styled.div``

export const BlockHalf = styled(Half)`
  width: 40vw;

  .ant-space-item {
    width: 100%;
  }

  ${md} {
    width: 100%;
  }
`

export const HeroTextBlock = styled.div<{ clickable?: boolean }>`
  overflow: hidden;
  color: black;
  font-size: 12px;
  border-radius: 20px;
  background-color: white;
  padding: 16px;
  position: relative;

  ${sm} {
    padding: 10px 14px;
  }

  .ant-typography.ant-typography-ellipsis {
    color: ${theme.colors["main-color-red"]};

    & * {
      color: rgba(0, 0, 0, 0.85);
    }
  }

  ${(props: { clickable?: boolean }) =>
    props.clickable
      ? `
  cursor: pointer;
  transition: ${theme.effects.transition.default};      
  `
      : ""}

  @media (max-height: 600px) {
    padding: 10px;
  }
`
