import React from "react"
import Image from "next/legacy/image"
import styled from "@emotion/styled"
import { md, slim, sm } from "../../styles/mediaQuery"
import { theme } from "../../styles/styles"

const FullWidthImage = ({ imageBgUrl, height, className }: Props) => {
  return (
    <FullWidthImageWrapper height={height} className={className}>
      <Image priority={true} alt="01_2022_vzdelani_budoucnosti_web_header_1920_1" src={imageBgUrl} layout="fill" objectFit="cover" />
    </FullWidthImageWrapper>
  )
}

const FullWidthImageWrapper = styled.div<{ height: string }>`
  position: relative;
  z-index: -2;
  transform: scaleX(-1);

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: linear-gradient(315deg, #919191, ${theme.colors["main-color-blue"]});
    background-size: 200% 200%;
    animation: AnimationName 8s ease infinite;
    z-index: 1;
    opacity: 0.65;
  }

  ${(props: { height: string }) => `
        height: ${props.height};
    `}
  ${md} {
    min-height: 55vh;
    height: initial;
  }

  ${sm} {
    min-height: 55vh;
    height: initial;
  }

  @media (max-height: 770px) and (orientation: landscape) and (max-width: 1279px) {
    min-height: 95vh;
    height: initial;
  }

  @media (max-height: 645px) and (orientation: landscape) {
    min-height: 100vh;
    height: initial;
  }

  ${slim} {
    min-height: 68vh;
    height: initial;
  }
`

interface Props {
  imageBgUrl: string
  height: string
  className?: string
}

export default FullWidthImage
